import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Fab, Button, Tooltip, Typography } from "@material-ui/core";
import {
  Add,
  Edit,
  Check,
  Close,
  Refresh,
  ArrowDropDownCircle,
  PictureAsPdf, Archive
} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import ReactTable from "react-table";
import "react-table/react-table.css";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import EditCandidate from "./editCandidate.js";
import Alert from "./../components/alert.js";
// import "typeface-roboto";
import * as jsPDF from "jspdf";
import AxiosAll from "./../axiosAll";
const axios = require('axios')

const FileSaver = require("file-saver");
const CheckboxTable = checkboxHOC(ReactTable);
const _ = require("lodash");
const moment = require("moment");

class ViewCandidates extends Component {
  state = {
    dialogOpen: false,
    selection: [],
    selectAll: false,
    candidate: [],
    disabled: false,
    loaded: false,
    name: "",
    isModTask: false,
    useCodes: false,
    taskYearGroup: -1,
    locked: true,
    pugTemplate: "",
    pugCandDetails: "",
    scans: [],
    country: '',
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    // console.log("componentDidMount", task);
    const promGetTask = AxiosAll.post(`/tasks/getTask`, {
      task: task,
      extras: ["withModTask"]
    });
    const promGetCands = AxiosAll.post(`/candidates/getCandidatesForTask`, {
      task: task
    });
    Promise.all([promGetTask, promGetCands]).then(oResults => {
      console.log("oResultXXX", oResults);
      const oCandidates = oResults[1].data;
      this.setState({
        name: oResults[0].data.name,
        locked: oResults[0].data.locked,
        isModTask: oResults[0].data.isModTask,
        useCodes: oResults[0].data.useCodes,
        taskYearGroup: oResults[0].data.yearGroup,
        taskOwners: oResults[0].data.owners,
        candidates: oCandidates,
        // scans: oResults[2].data,
        pugTemplate: oResults[0].data.pugTemplate,
        pugCandDetails: oResults[0].data.pugCandDetails,
        country: oResults[0].data.country,
        loaded: true
      });
    });
  }
  handleEditCandidate = e => {
    const { selection, candidates } = this.state;
    // console.log("handleEditCandidate", this.state);
    if (selection.length !== 1) return;
    this.setState({
      dialogOpen: true,
      candidate: selection[0],
      candInfo: _.find(candidates, { _id: selection[0] })
    });
  };
  handleModalClose = () => {
    this.setState({
      dialogOpen: false,
      candidate: null,
      selection: []
    });
  };
  toggleSelection = (key, shift, row) => {
    key = key.replace('select-', '')
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  };
  toggleAll = () => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach(item => {
        selection.push(item._original._id);
      });
    }
    this.setState({ selectAll, selection });
  };
  isSelected = key => {
    return this.state.selection.includes(key);
  };
  handleDownloadCandidates = oData => {
    const { name, isModTask } = this.state
    // console.log("oData", oData);
    const oCols = [
      { field: "Candidate", title: "Candidate" },
      { field: "qrcode", title: "Code" },
      { field: "firstName", title: "First Name" },
      { field: "lastName", title: "Last Name" },
      { field: "dob", title: "DOB" },
      { field: "gender", title: "Gender" },
      { field: "group", title: "Group" },
      { field: 'scaledScore', title: 'Scaled Score'},
      { field: "PP", title: "PP" },
      { field: "EAL", title: 'EAL/ESL' },
      { field: "yg", title: 'YG/Grade' },
      { field: "anchor", title: "Anchor" },
      { field: "anchorScore", title: "Anchor Score" },
    ];
    if (isModTask) {
      oCols.push({ field: 'localTask', title: 'From Task' })
      oCols.push({ field: 'fromTaskName', title: 'From Task Name' })
    }
    const arrCols = _.map(oCols, "field");
    let oResult = JSON.parse(JSON.stringify(oData));
    oResult = _.map(oResult, function(oRow) {
      oRow.localTask = name
      oRow.PP = oRow.PP ? "Y" : "N";
      oRow.EAL = oRow.EAL ? "Y" : "N";
      oRow.anchor = oRow.anchor ? "Y" : "N";
      _.each(arrCols, function(attr) {
        if (!_.has(oRow, attr)) oRow[attr] = "*";
        if (
          _.includes(["firstName", "lastName", "group"], attr) &&
          _.has(oRow, attr)
        )
          oRow[attr] = oRow[attr].replace(/,/, ".");
      });
      oRow.link = `${window.location.origin}/display/${oRow._id}`;
      return oRow;
    });
    oResult = _.map(oResult, function(oRow) {
      return _.values(_.pick(oRow, arrCols)).join(",");
    });
    const arrTitles = _.map(oCols, "title");
    oResult.unshift(arrTitles.join(","));
    const blob = new Blob([oResult.join("\n")], {
      type: "text/plain;charset=utf-8"
    });
    FileSaver.saveAs(blob, "candidates.csv");
  };
  doCloseEdit = refetch => {
    // console.log("doCloseEdit");
    this.setState({
      dialogOpen: false
    });
    refetch();
  };
  renderQR = cellInfo => {
    if (!cellInfo.original.hasScans)
      return (
        <div style={{ flex: 0, textAlign: "center" }}>
          {cellInfo.original.qrcode}
        </div>
      );
    return (
      <div style={{ flex: 0, textAlign: "center" }}>
        <NavLink to={`/display/${cellInfo.original._id}`} target="_blank">
          {cellInfo.original.qrcode}
        </NavLink>
      </div>
    );
  };
  handleModalClose = () => {
    const { match } = this.props;
    const task = match.params.task;

    const promGetCands = AxiosAll.post(`/candidates/getCandidatesForTask`, {
      task: task
    });
    const promGetScans = AxiosAll.post(`/scans/getScansForTask`, {
      task: task
    });

    Promise.all([promGetCands, promGetScans]).then(oResults => {
      // console.log("oResultXXX", oResults);
      const oCandidates = _.map(oResults[0].data, function(oCandidate) {
        const oScan = _.find(oResults[1].data, { _id: oCandidate.qrcode });
        if (!oScan) {
          oCandidate._pages = 0;
          return oCandidate;
        }
        oCandidate._pages = oScan.n;
        return oCandidate;
      });
      this.setState({
        candidates: oCandidates,
        dialogOpen: false
      });
    });
  };
  render() {
    const self = this;
    const { toggleSelection, toggleAll, isSelected } = this;
    const {
      selection,
      selectAll,
      dialogOpen,
      candidate,
      candInfo,
      disabled,
      loaded,
      name,
      isModTask,
      useCodes,
      taskYearGroup,
      taskOwners,
      locked,
      pugTemplate,
      pugCandDetails,
      candidates, country
    } = this.state;
    if (!loaded) return <span />;
    const { match } = this.props;
    const task = match.params.task;
    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox"
    };
    const arrGroups = _.uniq(_.map(candidates, "group"));
    // console.log("arrGroups", arrGroups);
    const columns = [
      {
        Header: "Code",
        accessor: "qrcode",
        maxWidth: 100,
        Cell: self.renderQR,
        filterMethod: (filter, row) =>
          row[filter.id].match(RegExp(filter.value, "i"))
      },
      {
        Header: "First Name",
        accessor: "firstName",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.firstName}
          </div>
        ),
        filterMethod: (filter, row) =>
          (row[filter.id] || '').match(RegExp(filter.value, "i"))
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.lastName}
          </div>
        ),
        filterMethod: (filter, row) =>
          (row[filter.id] || '').match(RegExp(filter.value, "i"))
      },
      {
        Header: "Group",
        accessor: "group",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.group}
          </div>
        ),
        filterMethod: (filter, row) =>
          row[filter.id].match(RegExp(filter.value, "i")),
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="">All</option>
            {arrGroups.map((group, i) => (
              <option key={`option_${i}`} value={group}>
                {group}
              </option>
            ))}
          </select>
        )
      },
      {
        Header: <span style={{ whiteSpace: 'normal' }}>Scaled Score</span>,
        maxWidth: 100,
        accessor: "scaledScore",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {isNaN(row.original.scaledScore) ||
            row.original.scaledScore === null
              ? "*"
              : row.original.scaledScore.toFixed(1)}
          </div>
        ),
        filterable: false
      },
      {
        accessor: "anchor",
        maxWidth: 70,
        Header: () => (
          <span>
            Anchor
            <br />
          </span>
        ),
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.anchor ? <Check /> : <Close />}
          </div>
        ),
        filterable: false
      },
      {
        accessor: "export",
        maxWidth: 70,
        Header: () => (
          <span>
            Export
            <br />
          </span>
        ),
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.export ? <Check /> : <Close />}
          </div>
        ),
        filterable: false
      },
      {
        accessor: "PP",
        maxWidth: 70,
        // show: (country === 'GB'),
        Header: () => (
          <span>
            PP
            <br />
          </span>
        ),
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.PP ? <Check /> : <Close />}
          </div>
        ),
        filterable: false
      },
      {
        accessor: "EAL",
        maxWidth: 70,
        Header: <span>EAL/<br />ESL</span>, // country === 'GB' ? 'EAL' : 'ESL',
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.EAL ? <Check /> : <Close />}
          </div>
        ),
        filterable: false
      },
      {
        Header: "Pages",
        maxWidth: 70,
        accessor: "_pages",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original._pages}
          </div>
        ),
        filterable: false
      },
      /*{
        Header: <span>From Task</span>,
        maxWidth: 150,
        accessor: 'taskName',
        filterMethod: (filter, row) => (row[filter.id] || '').match(RegExp(filter.value, "i")),
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.taskName || ''}
          </div>
        ),
      },*/
    ];
    if (isModTask) {
      columns.push({
        Header: <span>From Task</span>,
        maxWidth: 150,
        accessor: 'taskName',
        filterMethod: (filter, row) => (row[filter.id] || '').match(RegExp(filter.value, "i")),
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.taskName || ''}
          </div>
        ),
      })
    }
    let msg = <span />;
    if ((candidates || []).length === 0) {
      msg = (
        <Typography
          variant="subheading"
          color="secondary"
          gutterBottom
          style={{ textAlign: "center" }}
        >
          To get started, you need to import candidate details from a{" "}
          <Button
            mini
            variant="contained"
            style={{ textTransform: "inherit" }}
            onClick={this.props.changeTab1}
          >
            CSV file
          </Button>
          , import them from a{" "}
          <Button
            mini
            variant="contained"
            style={{ textTransform: "inherit" }}
            onClick={this.props.changeTab0}
          >
            school MIS
          </Button>{" "}
          or add them manually here by clicking on{" "}
          <Add style={{ height: "0.6em" }} />
        </Typography>
      );
    }
    const oCandidates = _.map(candidates, function(oCandidate) {
      let oCandidate2 = _.pick(oCandidate, [
        "_id",
        "qrcode",
        "firstName",
        "lastName",
        "group",
        "comparisons",
        "PP",
        "EAL",
        "scaledScore",
        "seScaledScore",
        "trueScore",
        "seTrueScore",
        "infit",
        "link",
        "score",
        "propScore",
        "anchor",
        "anchorScore",
        "infit",
        "anchor",
        "export",
        "absent",
        "selected",
        "fromModTask",
        "_fromModTaskName",
        "fromTask",
        "assigned",
        "hasScans",
        "scans",
        'taskName',
      ]);
      oCandidate2._pages = (oCandidate2.scans || []).length
      /*oCandidate2["_pages"] = _.filter(scans, {
        qrcode: oCandidate.qrcode
      }).length;*/
      return oCandidate2;
    });
    // console.log("state", this.state);
    if (
      locked &&
      useCodes &&
      _.intersection(_.map(candidates, "PP"), [true]).length === 0 &&
      candidates.length > 0
    )
      msg = (
        <Alert
          type="info"
          text="Currently none of your candidates have Pupil Premium set to true.  Without this information breakdown analysis can't be provided.&nbsp; Please email support@nomoremarking.com with your DfE if you do have Pupil Premium pupils and you continue to have problems."
        />
      );
    else if (locked && useCodes) {
      const arrCandYGs = _.uniq(_.map(candidates, "yg"));
      const arrCandsOtherYGs = _.difference(arrCandYGs, [taskYearGroup, taskYearGroup.toString()]);
      console.log('**', arrCandYGs, taskYearGroup, arrCandsOtherYGs, [taskYearGroup, taskYearGroup.toString()])
      if (arrCandsOtherYGs.length > 0) {
        msg = (
          <Alert
            type="error"
            text={`Candidates exist from other Year Groups: ${arrCandYGs.join(
              ", "
            )}`}
          />
        );
      }
    }
    console.log('state', this.state)
    return (
      <div>
        <div>
          {msg}
          <Tooltip title="Add Candidate">
            <Fab size="small"
              style={{ margin: "0 5px 5px 0" }}
              color="secondary"
              disabled={!useCodes}
              onClick={e => {
                this.setState({ disabled: true });
                let oNewCand = {
                  firstName: "",
                  lastName: "",
                  dob: "",
                  gender: "M",
                  group: "-",
                  PP: false,
                  EAL: false,
                  createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                  updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                  localTask: task
                };
                AxiosAll.post(`/candidates/createCandidates`, {
                  task: task,
                  candidates: [oNewCand]
                }).then(oResult => {
                  const oNewCands = oResult.data;
                  oNewCand._id = oNewCands[0]._id;
                  oNewCand.qrcode = oNewCands[0].qrcode;
                  // console.log("oResult createCandidates", oResult, oNewCands);
                  /*AxiosAll.post(`/judging/initialiseTaskJudging`, {
                    task: task
                  }).then(oRes => {*/
                    AxiosAll.post(`/candidates/getCandidatesForTask`, {
                      task: task
                    }).then(oCandidates => {
                      // console.log("oCandidates", oCandidates);
                      this.setState({
                        candidates: _.map(oCandidates.data, function(oCand) {
                          oCand._pages = 0;
                          return oCand;
                        }),
                        disabled: false,
                        dialogOpen: true,
                        candidate: oNewCands[0]._id,
                        candInfo: oNewCand
                      });
                    });
                  //});
                });
              }}
            >
              <Add />
            </Fab>
          </Tooltip>
          <Tooltip title="Edit Candidate">
            <Fab
              size="small"
              style={{ margin: "0 5px 5px 0" }}
              color="secondary"
              disabled={selection.length !== 1}
              onClick={self.handleEditCandidate}
            >
              <Edit />
            </Fab>
          </Tooltip>
          <Tooltip title="Delete Candidate(s)">
            <Fab
              size="small"
              style={{ margin: "0 5px 5px 0" }}
              color="secondary"
              disabled={selection.length === 0}
              onClick={e => {
                const oCands = _.filter(candidates, function(o) { return (_.includes(selection, o._id)) })
                const sConfirmText = `Delete Candidate(s)?\nAre you sure?\n\n${_.map(oCands, function(oCand) { return `${oCand.firstName || ''} ${oCand.lastName || ''}`.trim() }).join('\n')}`
                const r = window.confirm(sConfirmText);
                if (!r) return;
                console.log('=', task, selection)
                AxiosAll.post(`/candidates/deleteCandidates`, {
                  task: task,
                  ids: selection
                }).then(oResult => {
                  // console.log("removeCandidates", oResult);
                  this.setState({
                    candidates: oResult.data,
                  })
                  /*self.setState((prevState, curProps) => {
                    return {
                      candidates: _.filter(prevState.candidates, oCand => {
                        return _.indexOf(selection, oCand._id) === -1;
                      }),
                      selectAll: false,
                      selection: [],
                    };
                  });*/
                });
              }}
            >
              <DeleteIcon />
            </Fab>
          </Tooltip>
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={selection.length === 0}
            style={{ textTransform: "inherit", margin: "0 5px 5px 0" }}
            onClick={e => {
              const oFiltered = _(candidates)
                .keyBy("_id")
                .at(selection)
                .value();
              // console.log("oFiltered", oFiltered);
              const oPromises = _.map(oFiltered, function(oRow) {
                return AxiosAll.post(`/candidates/updateCandidate`, {
                  task: task,
                  candidate: oRow._id,
                  anchor: !oRow.anchor
                });
              });
              Promise.all(oPromises).then(o => {
                // console.log("o", o);
                this.setState(prevState => ({
                  candidates: _.map(prevState.candidates, function(oCandidate) {
                    if (_.indexOf(selection, oCandidate._id) > -1)
                      oCandidate.anchor = !oCandidate.anchor;
                    return oCandidate;
                  })
                }));
              });
            }}
          >
            Toggle Anchor
          </Button>

          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={selection.length === 0}
            style={{ textTransform: "inherit", margin: "0 5px 5px 0" }}
            onClick={e => {
              this.setState({ disabled: true });
              const oFiltered = _(candidates)
                .keyBy("_id")
                .at(selection)
                .value();
              const arrHasTrueScores = _.compact(_.map(oFiltered, "trueScore"));
              if (arrHasTrueScores.length !== oFiltered.length) {
                window.alert(
                  "All selected candidates must have a score before they can become an anchor"
                );
                return;
              }
              const oPromises = _.map(oFiltered, function(oRow) {
                return AxiosAll.post(`/candidates/updateCandidate`, {
                  task: task,
                  candidate: oRow._id,
                  export: !oRow.export
                });
              });
              Promise.all(oPromises).then(o => {
                // console.log("o", o);
                this.setState(prevState => ({
                  candidates: _.map(prevState.candidates, function(oCandidate) {
                    if (_.indexOf(selection, oCandidate._id) > -1)
                      oCandidate.export = !oCandidate.export;
                    return oCandidate;
                  }),
                  disabled: false
                }));
              });
            }}
          >
            Toggle Export
          </Button>

          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={selection.length === 0}
            style={{ textTransform: "inherit", margin: "0 5px 5px 0" }}
            onClick={e => {
              const oFiltered = _(candidates)
                .keyBy("_id")
                .at(selection)
                .value();
              const oPromises = _.map(oFiltered, function(oRow) {
                return AxiosAll.post(`/candidates/updateCandidate`, {
                  task: task,
                  candidate: oRow._id,
                  PP: !oRow.PP
                });
              });
              Promise.all(oPromises).then(o => {
                // console.log("o", o);
                this.setState(prevState => ({
                  candidates: _.map(prevState.candidates, function(oCandidate) {
                    if (_.indexOf(selection, oCandidate._id) > -1)
                      oCandidate.PP = !oCandidate.PP;
                    return oCandidate;
                  })
                }));
              });
            }}
          >
            Toggle PP
          </Button>

          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={selection.length === 0}
            style={{ textTransform: "inherit", margin: "0 5px 5px 0" }}
            onClick={e => {
              const oFiltered = _(candidates)
                .keyBy("_id")
                .at(selection)
                .value();
              const oPromises = _.map(oFiltered, function(oRow) {
                // console.log("updateCand", oRow);
                return AxiosAll.post(`/candidates/updateCandidate`, {
                  task: task,
                  candidate: oRow._id,
                  EAL: !oRow.EAL
                });
              });
              Promise.all(oPromises).then(o => {
                // console.log("o", o);
                this.setState(prevState => ({
                  candidates: _.map(prevState.candidates, function(oCandidate) {
                    if (_.indexOf(selection, oCandidate._id) > -1)
                      oCandidate.EAL = !oCandidate.EAL;
                    return oCandidate;
                  })
                }));
              });
            }}
          >
            Toggle EAL
          </Button>
          
          <Tooltip title="Download PDF with all Candidate responses">
          <Button
            size="small"
            variant="contained"
            color="primary"
            style={{ float: 'right', margin: "0 0 5px 5px" }}
            disabled={!_.includes(_.uniq(_.map(candidates, 'hasScans')), true)}
            onClick={async e => {
              const oSelCands = _.filter(candidates, { hasScans: true})
              const oScanLookups = await Promise.all(_.map(_.flatten(_.map(oSelCands, 'scans')), async url => {
                return await new Promise(function(resolve, reject) {
                  axios.get(url, {responseType: 'arraybuffer'})
                  .then(image => {
                    resolve({
                      url: url,
                      b64: Buffer.from(image.data).toString('base64')
                    })
                  })
                })
              }))
              let doc = new jsPDF({ orientation: "portrait" });
              doc.setFontSize(10)
              doc.setFont('helvetica')
              _.each(_.filter(candidates, { hasScans: true}), async (oCand, page) => {
                if (page > 0) doc.addPage()
                doc.text(`${oCand.qrcode}: ${oCand.firstName || ''} ${oCand.lastName || ''}`, 10, 10)
                _.each(oCand.scans, async (url, scanPage) => {
                    if (scanPage > 0) doc.addPage()
                    const b64 = _.find(oScanLookups, { url: url }).b64
                      doc.addImage(b64, 'JPG', 0, 15, 210, 296)
                })
              })
              doc.save('archive.pdf')
            }}><Archive /></Button></Tooltip>
          <Button
            size="small"
            variant="contained"
            color="primary"
            style={{
              float: "right",
              textTransform: "inherit",
              margin: "0 0 5px 5px"
            }}
            disabled={(candidates || []).length === 0 || disabled}
            onClick={() => self.handleDownloadCandidates(candidates)}
          >
            <ArrowDropDownCircle style={{ fontSize: "20px" }} /> Candidates
          </Button>

          <Tooltip title="Generate scripts for candidates, selecting rows will only generate pages for the selected candidates">
            <Button
              size="small"
              variant="contained"
              color="primary"
              style={{
                textTransform: "inherit",
                float: "right",
                margin: "0 0 5px 5px"
              }}
              disabled={
                disabled ||
                (candidates || []).length === 0 ||
                useCodes === false
              }
              onClick={e => {
                this.setState({ disabled: true });
                let oFilteredSelections =
                  selection.length === 0
                    ? candidates
                    : _.filter(candidates, function(o) {
                        return _.includes(selection, o._id);
                      });
                oFilteredSelections = _.filter(oFilteredSelections, function(o) { return (!o.anchor === true) })
                var doc = new jsPDF({ orientation: "portrait" });
                const pageCenter = 210 / 2;
                _.each(oFilteredSelections, function(oCand, page) {
                  let candPage = 1;
                  let pupil = "-";
                  switch (pugCandDetails) {
                    case "First Name / Last Name Initial / Date of Birth (DD/MM) / Class Name":
                      pupil = `${oCand.firstName} ${
                        (oCand.lastName || "").length > 0
                          ? oCand.lastName[0]
                          : ""
                      } (${moment(oCand.dob, "YYYY-MM-DD").format("DD/MM")}) [${
                        oCand.group
                      }]`;
                      break;
                    case "First Name / Last Name / Date of Birth (DD/MM/YYYY) / Class Name":
                      pupil = `${oCand.firstName} ${oCand.lastName} (${moment(
                        oCand.dob,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")}) [${oCand.group}]`;
                      break;
                    case "First Name / Last Name / Class Name":
                      pupil = `${oCand.firstName} ${oCand.lastName} [${
                        oCand.group
                      }]`;
                      break;
                    case "First Name / Last Name Initial / Class Name":
                      pupil = `${oCand.firstName} ${
                        (oCand.lastName || "").length > 0
                          ? oCand.lastName[0]
                          : ""
                      } [${oCand.group}]`;
                      break;
                    default:
                      pupil = "-";
                      break;
                  }

                  if (page > 0) doc.addPage();
                  _.each(pugTemplate.split("\n"), function(line) {
                    const arrAttrs = line.split(",");
                    if (arrAttrs.length > 0) {
                      switch (arrAttrs[0].toLowerCase()) {
                        case "img":
                          console.log('>', arrAttrs[1].replace(/&#44;/g, ','), arrAttrs[2], arrAttrs[3], arrAttrs[4], arrAttrs[5])
                          doc.addImage(arrAttrs[1].replace(/&#44;/g, ','), 'JPEG', arrAttrs[2], arrAttrs[3], arrAttrs[4], arrAttrs[5]) // img, x, y, w, h
                          break;
                        case "text":
                          doc.setFont(arrAttrs[5]);
                          doc.setFontSize(parseInt(arrAttrs[4]));
                          doc.text(
                            arrAttrs[3].replace(/&#34;/g, '"').replace(/&#44;/g, ',').replace(/&#63;/g, '?'),
                            parseInt(arrAttrs[1]),
                            parseInt(arrAttrs[2])
                          );
                          break;
                        case "line":
                          doc.setDrawColor(arrAttrs[5]);
                          doc.line(
                            parseInt(arrAttrs[1]),
                            parseInt(arrAttrs[2]),
                            parseInt(arrAttrs[3]),
                            parseInt(arrAttrs[4])
                          );
                          break;
                        case "newpage":
                          // Duplicate
                          doc.setFontSize(12);
                          doc.setFont("helvetica");
                          doc.text(
                            `Answer Page: Don't write below here`,
                            pageCenter,
                            260,
                            "center"
                          );
                          doc.setFontSize(14);
                          doc.text(`${oCand.qrcode}${candPage}`, 10, 270);
                          doc.setFontSize(12);
                          doc.text(pupil, 10, 278);
                          doc.setFontSize(10);
                          doc.text(name, 10, 285);
                          // Duplicate End
                          doc.addPage();
                          candPage++;
                          break;
                        default:
                          break;
                      }
                    }
                  });

                  // Duplicate
                  doc.setFontSize(12);
                  doc.setFont("helvetica");
                  doc.text(
                    `Answer Page: Don't write below here`,
                    pageCenter,
                    260,
                    "center"
                  );
                  doc.setFontSize(14);
                  doc.text(`${oCand.qrcode}${candPage}`, 10, 270);
                  doc.setFontSize(12);
                  doc.text(pupil, 10, 278);
                  doc.setFontSize(10);
                  doc.text(name, 10, 285);
                  // Duplicate End
                });
                doc.save("scripts.pdf");

                AxiosAll.post(`/candidates/addPDFContents`, {
                  task: task,
                  taskName: name,
                  taskOwners: taskOwners,
                  pugTemplate,
                  pugCandDetails,
                  candidates: _.map(oFilteredSelections, function(oCand) {
                    return _.pick(oCand, [
                      "firstName",
                      "lastName",
                      "dob",
                      "gender",
                      "group",
                      "qrcode",
                      "yg",
                      "PP",
                      "EAL",
                    ]);
                  })
                }).then(oResult => {
                  this.setState({ disabled: false });
                });
              }}
            >
              <PictureAsPdf style={{ fontSize: "20px" }} /> Generate PDFs
            </Button>
          </Tooltip>

          <Button
            size="small"
            variant="contained"
            color="primary"
            style={{
              textTransform: "inherit",
              float: "right",
              margin: "0 0 5px 5px"
            }}
            disabled={disabled || !isModTask}
            onClick={e => {
              this.setState({ disabled: true });
              AxiosAll.post(`/tasks/refreshModSample`, { task: task }).then(
                oResult => {
                  // console.log('refreshModSample', oResult)
                  const promScans = AxiosAll.post(
                    `/scans/getScannedPagesForTask`,
                    { task: task }
                  );
                  const promCands = AxiosAll.post(
                    `/candidates/getCandidatesForTask`,
                    { task: task }
                  );
                  Promise.all([promScans, promCands]).then(oResult => {
                    this.setState({
                      scans: oResult[0].data,
                      candidates: oResult[1].data,
                      disabled: false
                    });
                  });
                }
              );
            }}
          >
            <Refresh style={{ fontSize: "20px" }} /> Mod. Sample
          </Button>
          <CheckboxTable defaultPageSize={50}
            data={oCandidates}
            filterable
            columns={columns}
            minRows={0}
            showPagination={true}
            ref={r => (this.checkboxTable = r)}
            style={{ marginBottom: "20px" }}
            {...checkboxProps}
          />
          {candInfo && (
            <EditCandidate
              candidate={candidate}
              candInfo={candInfo}
              dialogOpen={dialogOpen}
              onCancel={self.handleModalClose}
              country={country}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(ViewCandidates);
