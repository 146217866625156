import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardHeader, CardContent, CardActions } from "@material-ui/core";
import { Grid, Button, Paper, Avatar, Divider } from "@material-ui/core";
import { School } from "@material-ui/icons";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Alert from "./../components/alert.js";
import AxiosAll from "./../axiosAll";

const _ = require("lodash");

class Anchors2 extends Component {
  state = {
    selTaskCands: [],
    selected: null,
    selTask: null,
    products: [],
    anchorSets: [],
    msg: <span />,
    loaded: false,
    disabled: false,
  };
  componentDidMount() {
    AxiosAll.post(`/purchases/getPurchases`).then(oResult => {
      const oPurchases = oResult.data;
      let arrPurchases = [];
      if (oPurchases.length === 1)
        arrPurchases = _.map(oPurchases[0].purchases, "productName");
      // console.log("getPurchases", oResult, arrPurchases);
      AxiosAll.post(`/candidates/getAnchorSets`, {
        products: arrPurchases
      }).then(oAnchorSets => {
        // console.log("oAnchorSets", oAnchorSets);
        this.setState({
          anchorSets: oAnchorSets.data,
          loaded: true
        });
      });
    });
  }
  render() {
    const self = this;
    const { selTask, selTaskCands, msg, anchorSets, loaded, disabled, selected } = this.state;
    if (!loaded) return <span />;
    const { match } = this.props;
    const task = match.params.task;
    const columns1 = [
      {
        Header: "Task Name",
        accessor: "name",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center", whiteSpace: 'normal' }}>
            {row.original.name}
          </div>
        ),
        filterMethod: (filter, row) =>
          row[filter.id].match(RegExp(filter.value, "i"))
      },
      {
        Header: "Date Created",
        accessor: "createdAt",
        width: 120,
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center", whiteSpace: 'normal' }}>
            {row.original.createdAt}
          </div>
        ),
        filterable: false,
      },
      {
        Header: "uMean",
        accessor: "scalingUMean",
        width: 80,
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {(row.original.scalingUMean || 0).toFixed(1)}
          </div>
        ),
        filterable: false,
      },
      {
        Header: "uiScale",
        accessor: "scalingUScale",
        width: 80,
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {(row.original.scalingUScale || 0).toFixed(1)}
          </div>
        ),
        filterable: false,
      }
    ];
    const columns2 = [
      {
        Header: "Candidate",
        accessor: "qrcode",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            <a
              href={`/display/${row.original._id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {row.original.qrcode}
            </a>
          </div>
        ),
      },
      {
        Header: "Scaled Score",
        accessor: "scaledScore",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.scaledScore === null
              ? "-"
              : row.original.scaledScore.toFixed(1)}
          </div>
        )
      },
      {
        Header: "Scaled Score SE",
        accessor: "seScaledScore",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.seScaledScore === null
              ? "-"
              : row.original.seScaledScore.toFixed(1)}
          </div>
        )
      }
    ];
    const oFiltered = anchorSets
    /*const oFiltered = _.filter(anchorSets, function(o) {
      return o.product === product;
    });*/ // Update to filter by Product Type
    let oSelected = {};
    if (selTask) oSelected = _.find(oFiltered, { _id: selTask });
    // // console.log("selTask", selTask, oFiltered, oSelected, anchorSets);
    // // console.log('state', this.state)
    return (
      <Grid container>
        <Grid item xs={12}>
          {msg}
          <Grid container>
            <Grid item xs={6}>
              <Paper elevation={4} style={{ marginRight: "10px" }}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar>
                        <School />
                      </Avatar>
                    }
                    title="Anchor Tasks"
                    subheader="Select a task from the table below to continue"
                  />
                  <Divider />
                  <CardContent>
                    <ReactTable defaultPageSize={50}
                      filterable
                      data={oFiltered}
                      columns={columns1}
                      minRows={0}
                      showPagination={true}
                      getTrProps={(state, rowInfo) => {
                        return {
                          onMouseOver: e => {
                            if (rowInfo.index !== selected) {
                              self.setState({ selected: rowInfo.index });
                            }
                          },
                          onMouseOut: e => {
                            if (selected !== null) {
                              self.setState({ selected: null });
                            }
                          },
                          onClick: e => {
                            /*self.setState({
                              selTask: rowInfo.original._id,
                              msg: <span />
                            });*/
                            AxiosAll.post(`/candidates/getAnchorSet`, {
                              task: rowInfo.original._id,
                            }).then(oAnchorSet => {
                              // console.log('oAnchorSet', oAnchorSet)
                              this.setState({
                                selTask: rowInfo.original._id,
                                msg: <span />,
                                selTaskCands: oAnchorSet.data,
                              })
                            })
                          },
                          style: {
                            background:
                              rowInfo.index === self.state.selected
                                ? "#313D4F"
                                : "white",
                            color:
                              rowInfo.index === self.state.selected
                                ? "white"
                                : "#313D4F"
                          }
                        };
                      }}
                    />
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={4} style={{ marginRight: "10px" }}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar>
                        <School />
                      </Avatar>
                    }
                    title={oSelected.name || "No Task Selected"}
                    subheader={
                      <span>
                        <strong>uMean: </strong>
                        {!oSelected.scalingUMean
                          ? "-"
                          : oSelected.scalingUMean.toFixed(1)}
                        &nbsp;&nbsp;&nbsp;<strong>uiScale: </strong>
                        {!oSelected.scalingUScale
                          ? "-"
                          : oSelected.scalingUScale.toFixed(1)}
                      </span>
                    }
                  />
                  <Divider />
                  <CardActions>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={!selTask || disabled}
                      style={{ textTransform: "inherit" }}
                      onClick={e => {
                        self.setState({ disabled: true })
                        AxiosAll.post(`/candidates/addAnchorSet`, {
                          toTask: task,
                          fromTask: selTask,
                          //anchorTask: oSelected
                          candidates: selTaskCands,
                        }).then(oResult => {
                          // console.log('addAnchorSet', oResult)
                          if (oResult.data.body === "") {
                            this.setState({
                              msg: <Alert text="Anchor set added" type="success" />,
                              disabled: false,
                            })
                          }
                          else {
                            this.setState({
                              msg: <Alert text={oResult.data.body} type="error" />,
                              disabled: false,
                            })
                          }
                        });
                      }}
                    >
                      Add Anchor Set to Current Task
                    </Button>
                  </CardActions>
                  <Divider />
                  <CardContent>
                    <ReactTable defaultPageSize={50}
                      data={selTaskCands}
                      columns={columns2}
                      minRows={0}
                      showPagination={true}
                    />
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Anchors2);
