// import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import {
  Grid,
  Fab,
  Typography,
  AppBar,
  Toolbar,
  Button,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  Tooltip,
  Badge,
} from "@material-ui/core";
import ImageZoom from "react-medium-image-zoom";

import AxiosAll from "./../axiosAll";
import TaskMenu2 from "./../layout/taskMenu2.js";
import ScansMenu from "./../layout/scansMenu.js";
import { Star, StarBorder, Done, StarHalf, FilterList } from "@material-ui/icons";
import { SizeMe } from "react-sizeme";

const _ = require("lodash");

class ScansBlanks extends Component {
  state = {
    scans: [],
    loaded: false,
    included: true,
    limit: 10,
    taskName: "",
    locked: false,
    isModTask: false,
    useCodes: false,
    selection: [],
    blanks: [],
    orderFileSize: true,
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    /*console.time('X')
    AxiosAll.post(`/scans/updateBlanksData`, { task: task })
    .then(oRes => {
      console.timeEnd('X')
      console.log('oRes', oRes)
    })*/
    const promTask = AxiosAll.post(`/tasks/getTask`, { task: task });
    const promScans = AxiosAll.post(`/scans/getScannedPagesForTask`, {
      task: task
    });
    // const promBlanks = AxiosAll.post(`/scans/updateBlanksData`, { task: task });
    const promMenu = AxiosAll.post(`/scans/getScanMenu`, { task: task });
    Promise.all([promTask, promScans, promMenu]).then(oResult => {
      this.setState({
        taskName: oResult[0].data.name,
        locked: oResult[0].data.locked,
        isModTask: oResult[0].data.isModTask,
        useCodes: oResult[0].data.useCodes,
        scans: _.orderBy(oResult[1].data, ["size", "asc"]),
        blanks: [], // oResult[2].data,
        loaded: true,
        pdfFiles: oResult[2].data.pdfFiles,
        codesToFix: oResult[2].data.codesToFix,
        candsWithScans: oResult[2].data.candsWithScans,
        hasDuplicates: oResult[2].data.hasDuplicates,
      });
    });
  }
  render() {
    const { match } = this.props;
    const task = match.params.task;
    const {
      taskName,
      locked,
      isModTask,
      useCodes,
      candsWithScans,
      scans,
      loaded,
      included,
      limit,
      selection,
      blanks, orderFileSize, pdfFiles, codesToFix, hasDuplicates
    } = this.state;
    if (!loaded) return <Grid style={{ paddingTop: '146px' }} container><Typography gutterBottom>Loading...</Typography></Grid>;
    if (isModTask) return <Typography gutterBottom>Not available for moderation tasks</Typography>
    let filtered = _.filter(scans, (oScan, i) => {
      if (included) {
        return !_.has(oScan, "exclude");
      } else {
        return _.has(oScan, "exclude");
      }
    });
    filtered = _.map(filtered, oFilter => {
      oFilter.amount =
        (_.find(blanks, { scan: oFilter._id }) || {}).amount || 0;
      return oFilter;
    });
    if (orderFileSize) {
      filtered = _.orderBy(filtered, ["size"], ["asc"]);
    }
    else {
      filtered = _.orderBy(filtered, ["amount"], ["desc"]);
    }
    
    if (limit > 0) {
      filtered = _.slice(filtered, 0, limit);
    }
    console.log("state", this.state);
    return (
      <Grid container style={{ paddingTop: '146px' }}>
        <Grid item xs={4}>
          <TaskMenu2
            taskName={taskName}
            locked={locked}
            isModTask={isModTask}
            useCodes={useCodes}
          />
        </Grid>
        <Grid item xs={8}>
          <ScansMenu
            tab={4}
            useCodes={useCodes}
            pdfFiles={pdfFiles}
            codesToFix={codesToFix}
            candsWithScans={candsWithScans}
            hasDuplicates={hasDuplicates}
          />
          <AppBar position="static">
            <Toolbar style={{ minHeight: "48px" }}>
              <Grid container style={{ flexGrow: 1 }}>
                <Grid item xs={12}>
                  <Grid container justify="space-between">
                    {/*<Grid item>
                      <Button color="inherit"><SwapVert xstyle={{ fontSize: '34px' }} /></Button>
                      <Button
                color="inherit"
                style={
                  included
                    ? { textTransform: "inherit", color: "#DD4A43" }
                    : { textTransform: "inherit" }
                }
                onClick={e => {
                  this.setState({ included: true });
                }}
              >
                Included
              </Button>
              <Button
                color="inherit"
                style={
                  included
                    ? { textTransform: "inherit" }
                    : { textTransform: "inherit", color: "#DD4A43" }
                }
                onClick={e => {
                  this.setState({ included: false });
                }}
              >
                Excluded
              </Button>
                      </Grid>*/}
                    {/*<Grid item>
                    <Button color="inherit">
                      <Sort />
                      </Button>
                      <Button color="inherit" style={orderFileSize ? { textTransform: "inherit", color: "#DD4A43" }
                    : { textTransform: "inherit" }} onClick={e => {
                      this.setState({ orderFileSize: true })
                    }}>
                        File Size
                      </Button>
                      Button color="inherit" style={orderFileSize ? { textTransform: "inherit" }
                    : { textTransform: "inherit", color: "#DD4A43" }} onClick={e => {
                      this.setState({ orderFileSize: false })
                    }}>
                        % Blank
                  </Button>
                  </Grid>*/}
                    <Grid item>
                    <Button color="inherit"><FilterList /></Button>
                      <Button
                color="inherit"
                style={
                  limit === 10
                    ? { textTransform: "inherit", color: "#DD4A43" }
                    : { textTransform: "inherit" }
                }
                onClick={e => {
                  this.setState({ limit: 10 });
                }}
              >
                10
              </Button>
              <Button
                color="inherit"
                style={
                  limit === 50
                    ? { textTransform: "inherit", color: "#DD4A43" }
                    : { textTransform: "inherit" }
                }
                onClick={e => {
                  this.setState({ limit: 50 });
                }}
              >
                50
              </Button>
              <Button
                color="inherit"
                style={
                  limit === 0
                    ? { textTransform: "inherit", color: "#DD4A43" }
                    : { textTransform: "inherit" }
                }
                onClick={e => {
                  this.setState({ limit: 0 });
                }}
              >
                All
              </Button>
                      </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          {/*<p>&nbsp;</p>
          <AppBar position="static" xstyle={{ marginBottom: "20px" }}>
            <Toolbar style={{ minHeight: "48px" }}>
              <Button
                color="inherit"
                style={
                  included
                    ? { textTransform: "inherit", color: "#DD4A43" }
                    : { textTransform: "inherit" }
                }
                onClick={e => {
                  this.setState({ included: true });
                }}
              >
                Included
              </Button>
              <Button
                color="inherit"
                style={
                  included
                    ? { textTransform: "inherit" }
                    : { textTransform: "inherit", color: "#DD4A43" }
                }
                onClick={e => {
                  this.setState({ included: false });
                }}
              >
                Excluded
              </Button>
              <Typography variant="body1" color="inherit" style={{ flex: 1 }} />
              <Button
                color="inherit"
                style={
                  limit === 10
                    ? { textTransform: "inherit", color: "#DD4A43" }
                    : { textTransform: "inherit" }
                }
                onClick={e => {
                  this.setState({ limit: 10 });
                }}
              >
                10
              </Button>
              <Button
                color="inherit"
                style={
                  limit === 50
                    ? { textTransform: "inherit", color: "#DD4A43" }
                    : { textTransform: "inherit" }
                }
                onClick={e => {
                  this.setState({ limit: 50 });
                }}
              >
                50
              </Button>
              <Button
                color="inherit"
                style={
                  limit === 0
                    ? { textTransform: "inherit", color: "#DD4A43" }
                    : { textTransform: "inherit" }
                }
                onClick={e => {
                  this.setState({ limit: 0 });
                }}
              >
                All
              </Button>
            </Toolbar>
          </AppBar>*/}
          <Typography gutterBottom style={{ marginTop: "20px" }}>
            Identify pages that are blank and exclude them using this page.
          </Typography>
          <Grid item xs={12}>
            <Tooltip title="Select/Deslect All">
              <Fab
                size="small"
                color="secondary"
                onClick={e => {
                  this.setState({
                    selection: selection.length ? [] : _.map(filtered, "_id")
                  });
                }}
              >
                <StarHalf />
              </Fab>
            </Tooltip>
            <Tooltip title="Toggle Include/Exclude">
              <Badge badgeContent={selection.length} color="primary">
                <Fab
                  size="small"
                  color="secondary"
                  style={{ margin: "0 0 0 15px" }}
                  disabled={selection.length === 0}
                  onClick={e => {
                    AxiosAll.post(`/scans/toggleExcludeImgs`, {
                      task: task,
                      selection: selection
                    }).then(oRes => {
                      AxiosAll.post(`/scans/getScannedPagesForTask`, {
                        task: task
                      }).then(oResult => {
                        this.setState({
                          scans: _.orderBy(oResult.data, ["size", "asc"]),
                          selection: []
                        });
                      });
                    });
                  }}
                >
                  <Done />
                </Fab>
              </Badge>
            </Tooltip>
          </Grid>
          {filtered.length === 0 && (
            <Typography
              gutterBottom
              color="secondary"
              style={{ margin: "15px 0 0 0" }}
            >
              No Images found
            </Typography>
          )}
          <SizeMe>
            {({ size }) => (
              <GridList
                cellHeight={282}
                cols={parseInt(size.width / 200)}
                style={{ margin: "15px 20px 20px 0" }}
              >
                {filtered.map((oScan, i) => (
                  <GridListTile key={`glt_${i}`} cols={1}>
                    <ImageZoom
                      image={{
                        src:
                          oScan.angle === 0
                            ? oScan.thumbnail0
                            : oScan.thumbnail180,
                        alt: `*`,
                        style: {
                          width: "200px",
                          height: "282px",
                          border: "1px solid #021a40",
                          margin: "5px",
                          padding: "5px"
                        }
                      }}
                      zoomImage={{
                        src: oScan.full_url,
                        alt: `*`
                      }}
                    />
                    <GridListTileBar title={oScan.qrpage === 0 ? '' : `${oScan.qrcode}${oScan.qrpage}`} titlePosition="top"
                    style={{ background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"}}
                     />
                    <GridListTileBar
                      style={{ background: "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"}}
                      actionIcon={
                        <IconButton
                          onClick={e => {
                            if (_.includes(selection, oScan._id)) {
                              this.setState({
                                selection: _.difference(selection, [oScan._id])
                              });
                            } else {
                              this.setState(prevState => ({
                                selection: [...prevState.selection, oScan._id]
                              }));
                            }
                          }}
                        >
                          {_.includes(selection, oScan._id) ? (
                            <Star />
                          ) : (
                            <StarBorder />
                          )}
                        </IconButton>
                      }
                    />
                  </GridListTile>
                ))}
              </GridList>
            )}
          </SizeMe>
        </Grid>
      </Grid>
    );
  }
}

export default ScansBlanks;
