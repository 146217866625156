require("es6-promise").polyfill();
const axios = require("axios");
const baseURL = process.env.REACT_APP_STAGE === 'prod' ? 'https://api.nomoremarking.com'  : 'https://dev-api.nomoremarking.com' 
console.log('baseURL', baseURL, process.env, window.location.hostname)
const AxiosAll = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: localStorage.getItem("token") || ""
  }
});
AxiosAll.interceptors.response.use(response => {
  console.log('response', response)
  if (response.hasOwnProperty('data')) {
    if ((response.data || {}).hasOwnProperty('error')) {
      console.log('ERROR', response.data)
      localStorage.clear();
      window.location.href = `${window.location.origin}?error=${JSON.stringify(response.data.error)}`
    }
  }

  console.log('interceptor response', response)
  return response
})

module.exports = AxiosAll;
