import React, { Component } from "react";
import {
Paper, AppBar, Tabs, Tab,
  ListItem,
  ListItemText,ListSubheader,
  Typography, Select, MenuItem, Button, Fab
} from "@material-ui/core";
import { Grid, Divider, List } from "@material-ui/core";
import { Check, Clear, Add, Delete } from "@material-ui/icons";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";
import ReactTable from "react-table";
import "react-table/react-table.css";
import PageTitle from "./../components/pageTitle.js";
import AxiosAll from "./../axiosAll";

const _ = require("lodash");
const moment = require('moment')
const oAcYears = [
    { value: moment().year(), label: `${moment().year()-1}-${moment().year()}` },
    { value: moment().year() + 1, label: `${moment().year()}-${moment().year() + 1}` },
  ]

class Trust extends Component {
  state = {
      loaded: false,
      trusts: [],
      XeroContacts: [],
      XeroInvoices: [],
      contacts: [],
      discountCodes: [],
      taskTypes: [],
      purchases: [],
      tasks: [],
      selected: null,
      tab: 0,
      taskYG: null,
      taskAcYear: moment().year(),
      oYGs: [],
  }
  componentDidMount() {
    AxiosAll.post(`/trust/getTrustsForUser`, {})
    .then(oRes => {
        const trusts = oRes.data
        if (trusts.length === 1) {
            AxiosAll.post(`/trust/getTrust`, { trust: trusts[0]._id })
            .then(oRes => {
                this.setState({
                    loaded: true,
                    trust: oRes.data,
                })
            })
        }
        else {
            this.setState({
                loaded: true,
                trusts: oRes.data,
            })
        }
    })
  }
  render() {
      const { loaded, XeroContacts, XeroInvoices, trust, contacts, discountCodes, taskTypes, purchases, tasks, trusts, selected, tab, taskYG, taskAcYear, oYGs } = this.state;
      if (!loaded) return <Grid style={{ paddingTop: '146px' }} container><Typography gutterBottom>Loading...</Typography></Grid>;
      const re = taskAcYear ? RegExp(`${taskAcYear - 1}-${taskAcYear}`) : RegExp(oAcYears[0].label)
      const oFilteredTTs = _.filter(taskTypes, oTT => {
        return (oTT.yearGroup === (taskYG || oYGs[0].value ) &&  re.test(oTT.name))
      })
      const arrSyllabus = _.map(oFilteredTTs, '_id')
      const oFilteredTasks = _.filter(tasks, oTask => {
        return (_.indexOf(arrSyllabus, oTask.syllabus) > -1)
      })
    console.log('state', this.state)
    return (
        <Grid
        container style={{ flexGrow: 1, paddingTop: '146px' }}>
            <Grid item xs={12} style={{ padding: '15px' }}>
            <PageTitle content="Trust Summary" />
            {trusts.length === 0 && (<Grid container>
                <Grid item xs={12}>
                <Typography gutterBottom><strong>This page is designed for Trust use only.</strong></Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography gutterBottom>Your email address is not currently part of any Trust, you can ask your Trust coordinator to add you.&nbsp; If you have any queries please email <a href="mailto:support@nomoremarking.com">support@nomoremarking.com</a>.</Typography>
                    </Grid>
                
            </Grid>)}
            {!trust && trusts.length > 0 && (<Grid container>
                <Grid item xs={12}>
                    <ReactTable data={trusts} columns={[
                        {
                            Header: 'Trust Name',
                            accessor: 'trustName',
                            Cell: row => (
                                <div style={{ flex: 0, textAlign: "center" }}>{row.original.trustName}</div>
                                    )
                        },
                        {
                            Header: 'Product',
                            accessor: 'productNameLong',
                            Cell: row => (
                                <div style={{ flex: 0, textAlign: "center" }}>{row.original.productNameLong}</div>
                                    )
                        },
                    ]} minRows={0} showPagination={false}
                    getTrProps={(state, rowInfo) => {
                        return {
                          onMouseOver: e => {
                            if (selected !== rowInfo.index) {
                              this.setState({ selected: rowInfo.index });
                            }
                          },
                          onMouseOut: e => {
                            if (selected !== null) {
                              this.setState({ selected: null });
                            }
                          },
                          onClick: e => {
                              console.log('click', trusts[rowInfo.index])
                              AxiosAll.post(`/trust/getTrust`, { trust: trusts[rowInfo.index]._id })
                              .then(oTrust => {
                                  const oYGs = _.uniq(_.map(oTrust.data.tasks, 'yearGroup')).sort()
                                  this.setState({
                                      trust: trusts[rowInfo.index]._id,
                                    XeroContacts: oTrust.data.XeroContacts,
                                    XeroInvoices: oTrust.data.XeroInvoices,
                                    contacts: oTrust.data.contacts,
                                    discountCodes: oTrust.data.discountCodes,
                                    taskTypes: oTrust.data.oTTs,
                                    purchases: oTrust.data.purchases,
                                    tasks: oTrust.data.tasks,
                                    oYGs: oYGs,
                                    taskYG: (oYGs.length > 0 ? oYGs[0] : null),
                                  })
                              })
                          },
                          style: {
                            background:
                              rowInfo.index === selected ? "#313D4F" : "white",
                            color: rowInfo.index === selected ? "white" : "#313D4F"
                          }
                        };
                      }}
                    />
                    </Grid>
                </Grid>)}
                {!!trust && (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography color="secondary" gutterBottom>{trusts[selected].trustName} ({trusts[selected].productNameLong})</Typography>
                            <Paper elevation={4}>
              <AppBar position="static">
                <Tabs value={tab} onChange={(e, v) => {
                  this.setState({ tab: v })
                }}>
                  <Tab label="Schools" />
                  <Tab label="Contacts" />
                  <Tab label="Discount Codes" />
                  <Tab label="Tasks" />
                  <Tab label="Invoicing" />
                </Tabs>
              </AppBar>
              {tab === 0 && <Grid item xs={12} style={{ padding: '15px' }}>
                <Typography>Alter DfEs/NCESs on the left to update schools in Trust</Typography>
              <ReactTable
                data={purchases}
                columns={[
                  {
                    Header: "DfE / NCES",
                    accessor: "dfe",
                    width: 130,
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center" }}>
                        {row.original.dfe}
                      </div>
                    )
                  },
                  {
                    Header: "Disc. %",
                    accessor: "discount",
                    width: 70,
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center" }}>
                        {row.original.discount}
                      </div>
                    )
                  },
                  {
                    Header: "School Name",
                    accessor: "School.schoolName",
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center", whiteSpace: 'normal' }}>
                        {row.original.School.schoolName}
                      </div>
                    )
                  },
                  {
                    Header: "Super User",
                    accessor: "UP.email",
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center" }}>
                        {_.has(row.original.UP, 'email') ? <a href={`mailto:${row.original.UP.email}`}>{row.original.UP.email}</a> : <span>-</span>}
                      </div>
                    )
                  },
                  {
                    Header: 'Year Groups',
                    accessor: 'ygs',
                    Cell: row => (
                        <div style={{ flex: 0, textAlign: "center" }}>
                            {row.original.ygs.join(', ')}
                        </div>
                    )
                  },
                  {
                    Header: <span>Signed<br />up?</span>,
                    accessor: "included",
                    width: 60,
                    Cell: row => (<div style={{ flex: 0, textAlign: "center" }}>{row.original.included ? <Check /> : <Clear />}</div>)
                  },
                ]}
                minRows={0}
                showPagination={true}
                style={{ marginTop: "20px" }}
              />
              </Grid>}
              {tab === 1 && <Grid item xs={12} style={{ padding: '15px' }}>
                <Typography gutterBottom>Users below will be added as school users giving access to all school tasks.</Typography>
                <Paper elevation={4}>
                    <Typography color="secondary" style={{ padding: '15px 0 0 15px' }}>Add Contact</Typography>
                    <Formik
                        initialValues={{ newEmail: '' }}
                        onSubmit={(values, { setSubmitting }) => {
                            console.log('add email submit', values)
                            setSubmitting(true)
                            AxiosAll.post(`/trust/addEmailToTrust`, { trust: trust, email: values.newEmail })
                            .then(oTrust => {
                                this.setState({
                                  contacts: oTrust.data.contacts,
                                  purchases: oTrust.data.purchases,
                                  tasks: oTrust.data.tasks,
                                  taskYG: (oYGs.length > 0 ? oYGs[0] : null),
                                })
                                setSubmitting(false)
                            })
                        }}
                        validationSchema={Yup.object().shape({
                            newEmail: Yup.string().email("Valid email required").notOneOf(_.map(contacts, 'email'), 'Must not be a current contact').required('Required')
                        })}
                    >
                        <Form>
                            <FormikTextField name="newEmail" label="Email Address" style={{ margin: '15px', width: '250px' }} />
                            <Button disabled type="submit" size="small" variant="contained" color="primary" style={{ margin: '30px 0 15px 15px' }}><Add /></Button>
                        </Form>
                    </Formik>
                </Paper>
              <ReactTable
                data={contacts}
                columns={[
                  {
                    Header: "Email",
                    accessor: "email",
                    //width: 240,
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center" }}>
                        <a href={`mailto:${row.original.email}`}>{row.original.email}</a>
                      </div>
                    )
                  },
                  {
                    Header: "Name",
                    accessor: "personFirstName",
                    //width: 200,
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center" }}>
                        {row.original.personFirstName} {row.original.personLastName}
                      </div>
                    )
                  },
                  {
                    Header: "Position",
                    accessor: "personJobTitle",
                    //width: 200,
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center" }}>
                        {row.original.personJobTitle}
                      </div>
                    )
                  },
                  {
                    Header: "Phone",
                    accessor: "personPhone",
                    //width: 200,
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center" }}>
                        {row.original.personPhone}
                      </div>
                    )
                  },
                  {
                    Header: <span>Has<br />Account?</span>,
                    accessor: 'hasAccount',
                    width: 80,
                    Cell: row => (
                        <div style={{ flex: 0, textAlign: "center" }}>
                            {row.original.hasAccount === false ? <Clear /> : <Check />}
                        </div>
                    )
                  },
                  {
                    Header: '',
                    accessor: '_id',
                    width: 60,
                    Cell: row => (
                        <Fab size="small" color="secondary" disabled={localStorage.getItem('email') === row.original.email || _.indexOf(_.map(_.map(purchases, 'School'), 'superUser'), row.original.user) > -1 || true} onClick={() => {
                            const r = window.confirm(`Delete Contact ${row.original.email}?`)
                            if (!r) return;
                            console.log('del contact', row.original._id)
                            AxiosAll.post(`/trust/removeEmailFromTrust`, { trust: trust, email: row.original.email })
                            .then(oTrust => {
                                const oYGs = _.uniq(_.map(oTrust.data.tasks, 'yearGroup')).sort()
                                this.setState({
                                  contacts: oTrust.data.contacts,
                                  purchases: oTrust.data.purchases,
                                  tasks: oTrust.data.tasks,
                                  oYGs: oYGs,
                                  taskYG: (oYGs.length > 0 ? oYGs[0] : null),
                                })
                            })
                        }}><Delete /></Fab>
                    )
                }
                ]}
                minRows={0}
                showPagination={true}
                style={{ marginTop: "20px" }}
              />
              </Grid>}
              {tab === 2 && <Grid item xs={12} style={{ padding: '15px' }}>
              <ReactTable
                data={discountCodes}
                columns={[
                  {
                    Header: "Code",
                    accessor: "code",
                    width: 200,
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center" }}>
                        {row.original.code}
                      </div>
                    )
                  },
                  {
                    Header: "Discount %",
                    accessor: "discount",
                    width: 120,
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center" }}>
                        {row.original.discount}
                      </div>
                    )
                  },
                  {
                    Header: "Label",
                    accessor: "label",
                    width: 100,
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center" }}>
                        {row.original.label}
                      </div>
                    )
                  },
                ]}
                minRows={0}
                showPagination={true}
                style={{ marginTop: "20px" }}
              />
              </Grid>}
              {tab === 3 && <Grid item xs={12} style={{ padding: '15px' }}>
                <Grid container>
                  <Grid item xs={6} style={{ padding: '10px' }}>
                    <Select
                      value={taskYG}
                      onChange={(e, v) => this.setState({ taskYG: v.props.value }) }
                      fullWidth
                    >
                      {oYGs.map((v, i) => <MenuItem key={`yg_${i}`} value={v}>{`Y${v}`}</MenuItem>)}
                    </Select>
                  </Grid>
                  <Grid item xs={6} style={{ padding: '10px' }}>
                    <Select
                      value={taskAcYear}
                      onChange={(e, v) => this.setState({ taskAcYear: v.props.value }) }
                      fullWidth
                    >
                      {_.map(oAcYears, (v, i) => <MenuItem key={`taskAcYear_${v.value}`} value={v.value}>{v.label}</MenuItem>)}
                    </Select>
                  </Grid>
                </Grid>
              <ReactTable
                data={oFilteredTasks}
                columns={[
                  {
                    Header: "Name",
                    accessor: "name",
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center" }}>
                        {row.original.name}
                      </div>
                    )
                  },
                  {
                    Header: 'Cands.',
                    accessor: "_candidates",
                    width: 80,
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center" }}>
                        {row.original._candidates}
                      </div>
                    )
                  },
                  {
                    Header: 'Judges',
                    accessor: '_judges',
                    width: 80,
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center" }}>
                        {row.original._judges}
                      </div>
                    )
                  },
                  {
                    Header: 'Quota',
                    accessor: '_quota',
                    width: 80,
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center" }}>
                        {row.original._quota}
                      </div>
                    )
                  },
                  {
                    Header: 'Judgements',
                    accessor: '_judgements',
                    width: 80,
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center" }}>
                        {row.original._judgements}
                      </div>
                    )
                  }
                ]}
                minRows={0}
                showPagination={true}
                style={{ marginTop: "20px" }}
              />
              </Grid>}
              {tab === 4 && (<Grid item xs={12} style={{ padding: '15px' }}>
              <Typography>Info from Xero</Typography>
                  <Typography style={{ margin: '20px 0 0 0' }} variant="h6">Contacts</Typography>
                  {XeroContacts.length === 0 && <Typography>No Contacts</Typography>}
                    {XeroContacts.map(contact => (<Paper key={`xeroContact_${contact._id}`} elevation={4}><List key={`contacts_${contact._id}`} subheader={<ListSubheader>{`${contact.FirstName} ${contact.LastName} `} (<a href={`mailto:${contact.EmailAddress}`}>{contact.EmailAddress}</a>)</ListSubheader>}>
                        <Divider />
                            {contact.Addresses.map((address, j) => (
                              <ListItem key={`add_${contact._id}_${j}`} divider>
                                <ListItemText primary={address.AddressType} secondary={_.compact(_.values(_.omit(address, 'AddressType'))).join(', ')} />
                              </ListItem>
                            ))}
                      </List></Paper>)
                    )}

                  <Typography style={{ margin: '20px 0 0 0' }} variant="h6">Invoices</Typography>
                  <ReactTable data={XeroInvoices} columns={[
                    {
                      Header: 'No',
                      accessor: 'InvoiceNumber',
                      width: 90,
                      Cell: row => (
                        <div style={{ flex: 0, textAlign: "center" }}>
                          {row.original.InvoiceNumber}
                        </div>
                      )
                    },
                    {
                      Header: 'Raised',
                      accessor: 'DateString',
                      width: 110,
                      Cell: row => (
                        <div style={{ flex: 0, textAlign: "center" }}>
                          {row.original.DateString.substring(0,10)}
                        </div>
                      )
                    },
                    {
                      Header: 'Due Date', 
                      accessor: 'DueDateString',
                      width: 110,
                      Cell: row => (
                        <div style={{ flex: 0, textAlign: "center" }}>
                        {row.original.DueDateString.substring(0,10)}
                      </div>
                      )
                    },
                    {
                      Header: 'Due Amount', 
                      accessor: 'AmountDue',
                      width: 110,
                      Cell: row => (
                        <div style={{ flex: 0, textAlign: "center" }}>
                        £{row.original.AmountDue}
                      </div>
                      )
                    },
                    {
                      Header: 'Paid Amount', 
                      accessor: 'AmountPaid',
                      width: 110,
                      Cell: row => (
                        <div style={{ flex: 0, textAlign: "center" }}>
                        £{row.original.AmountPaid}
                      </div>
                      )
                    },
                    {
                      Header: 'Status',
                      accessor: 'Status',
                      width: 80,
                      Cell: row => (
                        <div style={{ flex: 0, textAlign: "center" }}>
                          {row.original.Status}
                        </div>
                      )
                    },
                    {
                      Header: 'Payments',
                      accessor: 'Payments',
                      // width: 180,
                      Cell: row => (
                        <div style={{ flex: 0, textAlign: "center" }}>
                          {row.original.Payments.map((payment, i) => (
                            <div key={`key_${payment.PaymentID}`}>
                            {`£${payment.Amount} (${moment(parseFloat(payment.Date.replace('/Date(', '').replace(')/', ''))).format('YYYY-MM-DD')})`}
                            </div>
                          ))}
                        </div>
                      )
                    }
                  ]}
                  minRows={0}
                  showPagination={true}
                  />
                </Grid>)}
              </Paper>
                            </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
  }
}

export default Trust